import RateLastIcon from '@mui/icons-material/People';

import LastQuotesList from './LastQuotesList';
import LastQuotesCreate from './LastQuotesCreate';
import LastQuotesEdit from './LastQuotesEdit';

const resource = {
    list: LastQuotesList,
    // create: LastQuotesCreate,
    edit: LastQuotesEdit,
    icon: RateLastIcon,
};

export default resource;
