import * as React from 'react';

import {
    Create,
    DateInput,
    SimpleForm,
    TextInput,
    useTranslate,
    PasswordInput,
    email,
    BooleanInput,
    NumberInput,
    ReferenceInput,
    SelectInput,
    useNotify,
    useRedirect,
} from 'react-admin';

import { Box, Typography } from '@mui/material';
import { redirect, useLocation } from 'react-router';


export const validateForm = (
    values: Record<string, any>
): Record<string, any> => {
    const errors = {} as any;
    if (!values.name) {
        errors.name = 'ra.validation.required';
    }
    return errors;
};


const categoryField = (record: any) => `#${record.id} #${record.name}`;



const PlanCreate = () => {

    const notify = useNotify();
    const redirect = useRedirect();
    const location = useLocation();

    const onSuccess = () => {
        notify(`Changes saved`);
        redirect('/tariff/plans');
    };


    return (
        <Create mutationOptions={{ onSuccess }}>
            <SimpleForm
                sx={{ maxWidth: 500 }}
                // Here for the GQL provider
                defaultValues={{
                    administrator: false,
                    moderator: true,
                    active: true,
                }}
                validate={validateForm}
            >
                <SectionTitle label="resources.tariff.plans.fieldGroups.main" />

                <TextInput source="name" isRequired fullWidth label="resources.tariff.plans.fields.name" />
                <TextInput source="description" fullWidth label="resources.tariff.plans.fields.description" />


                <NumberInput source="firstStageMinProfitPcnt" isRequired fullWidth label="resources.tariff.plans.fields.firstStageMinProfitPcnt"/>
                <NumberInput source="firstStageMaxLossPcnt" isRequired fullWidth label="resources.tariff.plans.fields.firstStageMaxLossPcnt"/>
                <NumberInput source="firstStageDaysCount" isRequired fullWidth label="resources.tariff.plans.fields.firstStageDaysCount"/>
                <NumberInput source="secondStageMinProfitPcnt" isRequired fullWidth label="resources.tariff.plans.fields.secondStageMinProfitPcnt"/>
                <NumberInput source="secondStageMaxLossPcnt" isRequired fullWidth label="resources.tariff.plans.fields.secondStageMaxLossPcnt"/>
                <NumberInput source="secondStageDaysCount" isRequired fullWidth label="resources.tariff.plans.fields.secondStageDaysCount"/>



                

                <NumberInput source="costProcent" isRequired fullWidth label="resources.tariff.plans.fields.costProcent"/>
                <NumberInput source="realCostUsdt" isRequired fullWidth label="resources.tariff.plans.fields.realCostUsdt"/>
                <NumberInput source="startBalanceUsdt" isRequired fullWidth label="resources.tariff.plans.fields.startBalanceUsdt"/>
                <NumberInput source="leverage" fullWidth label="resources.tariff.plans.fields.leverage"/>

                <Separator />
                <SectionTitle label="resources.tariff.plans.fieldGroups.status" />
                <BooleanInput source="active" fullWidth label="resources.tariff.plans.fields.active"/>

            </SimpleForm>
        </Create>
    )

};


const SectionTitle = ({ label }: { label: string }) => {
    const translate = useTranslate();

    return (
        <Typography variant="h6" gutterBottom>
            {translate(label as string)}
        </Typography>
    );
};

const Separator = () => <Box pt="1em" />;


export default PlanCreate;

function notify(arg0: string) {
    throw new Error('Function not implemented.');
}

function refresh() {
    throw new Error('Function not implemented.');
}

