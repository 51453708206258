import InvoicesUsdtList from './InvoiceList';
import InvoicesUsdtCreate from './InvoicesUsdtCreate';
import InvoicesUsdtEdit from './InvoicesUsdtEdit';
import InvoicesUsdtIcon from '@mui/icons-material/People';

export default {
    list: InvoicesUsdtList,
    create: InvoicesUsdtCreate,
    edit: InvoicesUsdtEdit,
    icon: InvoicesUsdtIcon,
};
