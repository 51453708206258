import * as React from 'react';

import {
    DateInput,
    Edit,
    NullableBooleanInput,
    TextInput,
    PasswordInput,
    SimpleForm,
    useTranslate,
    BooleanInput,
    ReferenceInput,
    SelectInput,
    NumberInput,
    TextField,
} from 'react-admin';

import { Grid, Box, Typography } from '@mui/material';

import { validateForm } from './InvoicesUsdtCreate';



// const PaymentTitle = () => <FullNameField size="32" sx={{ margin: '5px 0' }} />;

const stageField = (record: any) => `#${record.tariffPlanId} #${record.name}`;



const InvoicesUsdtEdit = () => (

    <Edit>
        <SimpleForm
            sx={{ maxWidth: 500 }}
            validate={validateForm}
        >
            <Separator />
            <SectionTitle label="resources.payments.invoicesUsdt.fieldGroups.main" />

            <TextInput source='invoiceCost' label="resources.payments.invoicesUsdt.fields.invoiceCost" />
            <TextInput source='tempWalletAddress' label="resources.payments.invoicesUsdt.fields.tempWalletAddress" />
            <TextInput source='tempWalletPrivateKey' label="resources.payments.invoicesUsdt.fields.tempWalletPrivateKey" />
            <TextInput source='tempWalletPublicKey' label="resources.payments.invoicesUsdt.fields.tempWalletPublicKey" />
            <TextInput source='status' label="resources.payments.invoicesUsdt.fields.status" />
            <TextInput source='statusTransfer' label="resources.payments.invoicesUsdt.fields.statusTransfer" />
            <NumberInput source='mainWalletId' label="resources.payments.invoicesUsdt.fields.mainWalletId" />

        </SimpleForm>

    </Edit>
);


const SectionTitle = ({ label }: { label: string }) => {
    const translate = useTranslate();

    return (
        <Typography variant="h6" gutterBottom>
            {translate(label as string)}
        </Typography>
    );
};

const Separator = () => <Box pt="1em" />;


export default InvoicesUsdtEdit;
