import * as React from 'react';

import {
    DateInput,
    Edit,
    NullableBooleanInput,
    TextInput,
    PasswordInput,
    SimpleForm,
    useTranslate,
    BooleanInput,
    ReferenceInput,
    SelectInput,
    NumberInput,
    TextField,
    BooleanField,
} from 'react-admin';

import { Grid, Box, Typography } from '@mui/material';

import { validateForm } from './MainWalletCreate';



// const PaymentTitle = () => <FullNameField size="32" sx={{ margin: '5px 0' }} />;

const stageField = (record: any) => `#${record.tariffPlanId} #${record.name}`;



const InvoicesUsdtEdit = () => (

    <Edit>
        <SimpleForm
            sx={{ maxWidth: 500 }}
            // validate={validateForm}
        >
            <Separator />
            <SectionTitle label="resources.payments.mainWallet.fieldGroups.main" />

            

            {/* <TextInput source='mainWalletAddress' label="resources.payments.mainWallet.fields.mainWalletAddress" />
            <TextInput source='mainWalletPublicKey' label="resources.payments.mainWallet.fields.mainWalletPublicKey" />
            <TextInput source='ballanceUsdt' label="resources.payments.mainWallet.fields.ballanceUsdt" />
            <TextInput source='ballanceTrx' label="resources.payments.mainWallet.fields.ballanceTrx" />
            <TextInput source='trxAmount' label="resources.payments.mainWallet.fields.trxAmount" />
            <TextInput source='feeLimit' label="resources.payments.mainWallet.fields.feeLimit" />
            <BooleanInput source='default' label="resources.payments.mainWallet.fields.default" /> */}
            <BooleanInput source='deleted' label="resources.payments.mainWallet.fields.deleted" />

        </SimpleForm>

    </Edit>
);


const SectionTitle = ({ label }: { label: string }) => {
    const translate = useTranslate();

    return (
        <Typography variant="h6" gutterBottom>
            {translate(label as string)}
        </Typography>
    );
};

const Separator = () => <Box pt="1em" />;


export default InvoicesUsdtEdit;
