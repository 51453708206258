import MainWalletList from './MainWalletList';
import MainWalletCreate from './MainWalletCreate';
import MainWalletEdit from './MainWalletEdit';
import MainWalletIcon from '@mui/icons-material/People';

export default {
    list: MainWalletList,
    create: MainWalletCreate,
    edit: MainWalletEdit,
    icon: MainWalletIcon,
};
