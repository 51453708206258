import { TranslationMessages } from "react-admin";
import englishMessages from "ra-language-english";
import { symbol } from "prop-types";
import { min } from "date-fns";

const customEnglishMessages: TranslationMessages = {
  ...englishMessages,
  pos: {
    search: "Search",
    configuration: "Configuration",
    language: "Language",
    theme: {
      name: "Theme",
      light: "Light",
      dark: "Dark",
    },
    dashboard: {
      month_history: "History",
      new_customers: "New Customers",
      all_customers: "See all customers",
      welcome: {
        title: "",
        subtitle: "",
        ra_button: "",
        demo_button: "",
      },
    },
    menu: {
      profile: "Profile",
      tariff: "Tariff",
      marketdata: "Market Data",
      billing: "Billing",
      payments: "Payments",
      faq: "FAQ",
      helppage: "Help Page",
      mainpage: "Main Page",
      news: "News",

      trading: "Trading",
      catalog: "Catalog",
      contest: "Contest",
    },
  },

  resources: {
    profile: {
      role: {
        name: "Roles",
        fields: {
          id: "Id",
          name: "Name",
          description: "Description",
          administrator: "Administrator",
          moderator: "Moderator",
          active: "Active",
          deleted: "Deteled",
          createdAt: "Created At",
          updatedAt: "Updated At",
        },
        filters: {
          administrator: "Administrator",
          moderator: "Moderator",
        },
        fieldGroups: {
          main: "Main",
          status: "Status",
        },
        page: {
          delete: "Delete Role",
        },
        errors: {},
      },
      users: {
        name: "Users",
        fields: {
          id: "id",
          profileRoleId: "Role Identifier",
          totalBeginOpen: "Initial balance",
          totalLast: "Closing balance",
          totalBeginChange: "Portfolio growth",
          totalBeginPcnt: "Portfolio growth (%)",
          username: "Username",
          password: "Password",
          confirm_password: "Confirm password",
          email: "E-mail",
          phone: "Phone",
          avatar: "Avatar",
          firstName: "First Name",
          lastName: "Last Name",
          role: "Role",
          firstSeen: "First Seen",
          lastSeen: "Last Seen",
          administrator: "Administrator",
          moderator: "Moderator",
          active: "Active",
          deleted: "Deteled",
          createdAt: "Created At",
          updatedAt: "Updated At",
        },
        filters: {
          last_visited: "Last visited",
          today: "Today",
          this_week: "This week",
          last_week: "Last week",
          this_month: "This month",
          last_month: "Last month",
          earlier: "Earlier",
          has_ordered: "Has ordered",
          has_newsletter: "Has newsletter",
          group: "Segment",
        },
        fieldGroups: {
          identity: "Identity",
          role: "Role",
          history: "History",
          password: "Password",
          change_password: "Change Password",
          status: "Status",
        },
        page: {
          delete: "Delete User",
        },
        errors: {
          password_mismatch:
            "The password confirmation is not the same as the password.",
        },
      },
    },

    tariff: {
      categories: {
        name: "Categories",
        fields: {
          id: "Id",
          name: "Name",
          description: "Description",
          active: "Active",
          deleted: "Deteled",
          createdAt: "Created At",
          updatedAt: "Updated At",
        },
        filters: {},
        fieldGroups: {
          main: "Main",
          status: "Status",
        },
        page: {
          delete: "Delete Category",
        },
        errors: {},
      },
      plans: {
        name: "Plan's",
        fields: {
          id: "Id",
          name: "Name",
          description: "Description",
          category: "Category",
          costProcent: "Tariff cost (start in %)",
          fixedCostUsdt: "Fix cost (USDT)",
          startBalance: "Start balance",
          leverage: "Leverage",
          firstStageMinProfitPcnt: "Min profit (%)",
          firstStageMaxLossPcnt: "Max loss (%)",
          firstStageDaysCount: "Days count",
          secondStageMinProfitPcnt: "Min profit (%)",
          secondStageMaxLossPcnt: "Max loss (%)",
          secondStageDaysCount: "Days count",
          startBalanceUsdt: "Start balance (USDT)",
          realCostUsdt: "Real cost (USDT)",




          active: "Active",
          deleted: "Deteled",
          createdAt: "Created At",
          updatedAt: "Updated At",
        },
        filters: {},
        fieldGroups: {
          main: "Main",
          status: "Status",
        },
        page: {
          delete: "Delete Tariff",
        },
        errors: {},
      },
      stages: {
        name: "Stage's",
        fields: {
          id: "Id",
          name: "Name",
          tariffPlanId: "Tariff",
          minProfitPerStageProcent: "Min stage profit (%)",
          maxLossPerStageProcent: "Max stage loss (%)",
          tradingDaysCount: "Trading day count",
          minProfitPerDayProcent: "Min profit per trading day, %",
          maxLossPerDayProcent: "Max loss per trading day, %",
          ordering: "Orders",
          active: "Active",
          deleted: "Deteled",
          createdAt: "Created at",
          updatedAt: "Updated at",
        },
        filters: {},
        fieldGroups: {
          main: "Main",
          status: "Status",
        },
        page: {
          delete: "Delete Stage",
        },
        errors: {},
      },
    },

    marketdata: {
      coin: {
        name: "Coins",
        fields: {
          id: "Id",
          name: "Name",
          type: "type",

          symbol: "Symbol",

          datetimeUnixtimeMs: "Unix timestamp (ms)",
          datetimeUnixtimeS: "Unix timestamp (sec)",
          datetimeIso: "ISO date time format",
          bidPrice: "Bid price",
          bidSize: "Bid quantity",
          askPrice: "Ask price",
          askSize: "Ask quantity",
          lastPrice: "Last price",
          prevPrice24h: "Previous price (24h)",
          price24hOpen: "Open price (24h)",
          price24hChange: "Price change (24h)",
          price24hPcnt: "24h change (%)",
          highPrice24h: "Price high (24h)",
          lowPrice24h: "Price low (24h)",
          turnover24h: "24h turnover",
          volume24h: "24h volume",
          usdIndexPrice: "Index price (USD)",
          source: "Source",

          active: "Active",
          deleted: "Deteled",
          createdAt: "Created At",
          updatedAt: "Updated At",
        },
        filters: {},
        fieldGroups: {
          main: "Main",
          status: "Status",
        },
        page: {
          delete: "Delete Coin",
        },
        errors: {},
      },

      quotesLast: {
        name: "Quotes Last",
        fields: {
          id: "Id",
          datetimeUnixtimeMs: "Unix timestamp (ms)",
          datetimeUnixtimeS: "Unix timestamp (sec)",
          datetimeIso: "ISO date time format",
          bidPrice: "Bid price",
          bidSize: "Bid quantity",
          askPrice: "Ask price",
          askSize: "Ask quantity",
          lastPrice: "Last price",
          prevPrice24h: "Previous price (24h)",
          price24hOpen: "Open price (24h)",
          price24hChange: "Price change (24h)",
          price24hPcnt: "24h change (%)",
          highPrice24h: "Price high (24h)",
          lowPrice24h: "Price low (24h)",
          turnover24h: "24h turnover",
          volume24h: "24h volume",
          usdIndexPrice: "Index price (USD)",
          source: "Source",

          marketdataPairId: "Market pair id",
          costByUsd: "Cost by USD",
          createdAt: "Created At",
          updatedAt: "Updated At",
        },
        filters: {},
        fieldGroups: {
          main: "Main",
          status: "Status",
        },
        page: {
          delete: "Delete Rate Last",
        },
        errors: {},
      },

      quotes: {
        name: "Quotes",
        fields: {
          id: "Ids",
          datetimeUnixtimeMs: "Unix timestamp (ms)",
          datetimeUnixtimeS: "Unix timestamp (sec)",
          datetimeIso: "ISO date time format",
          bidPrice: "Bid price",
          bidSize: "Bid quantity",
          askPrice: "Ask price",
          askSize: "Ask quantity",
          lastPrice: "Last price",
          prevPrice24h: "Previous price (24h)",
          price24hOpen: "Open price (24h)",
          price24hChange: "Price change (24h)",
          price24hPcnt: "24h change (%)",
          highPrice24h: "Price high (24h)",
          lowPrice24h: "Price low (24h)",
          turnover24h: "24h turnover",
          volume24h: "24h volume",
          usdIndexPrice: "Index price (USD)",
          source: "Source",
          marketdataPairId: "Market pair id",
          createdAt: "Created At",
          updatedAt: "Updated At",
        },
        filters: {},
        fieldGroups: {
          main: "Main",
          status: "Status",
        },
        page: {
          delete: "Delete Rate Last",
        },
        errors: {},
      },
      pairs: {
        name: "Pairs",
        fields: {
          id: "Ids",
          coin: "Coin",
          type: "Type",
          baseAssetId: "Base asset ID",
          quoteAssetId: "Quote asset ID",
          symbol: "Symbol",
          active: "Active",
          deleted: "Deteled",
          name: "Name",
          description: "Description",
          image: "Image",
          timezone: "Timezone",
          minmov: "Min movement",
          minmov2: "Min movement 2",
          pricescale: "Price scale",
          pointvalue: "Point value",
          session: "Session",
          visiblePlotsSet: "Visible plots set",
          hasIntraday: "Has intraday",
          costByUsd: "Cost by USD",
          createdAt: "Created At",
          updatedAt: "Updated At",
        },
        filters: {},
        fieldGroups: {
          main: "Main",
          status: "Status",
        },
        page: {
          delete: "Delete Rate Last",
        },
        errors: {},
      },
    },

    billing: {
      account: {
        name: "Accounts",
        fields: {
          id: "Id",
          invoiceCoin: "Invoice coin",
          invoiceCost: "Invoice cost",
          totalAccountChangePcnt: "Account change (%)",
          currentStageNumber: "Current stage number",
          firstStageBegin: "First stage begin",
          firstStageLast: "First stage last",
          firstStageChange: "First stage change",
          firstStageChangePcnt: "First stage change (%)",
          firstStageMinProfitPcntFlag: "First stage min profit (%)",
          firstStageMaxLossPcntFlag: "First stage max loss (%)",
          realCostUsdt: "Real cost bu USDT",
          firstStageDayNumber: "First stage day number",
          firstStageCompletePcnt: "First stage complete (%)",
          secondStageBegin: "Second stage begin",
          secondStageLast: "Second stage last",
          secondStageChange: "Second stage change",
          secondStageChangePcnt: "Second stage change (%)",
          secondStageMinProfitPcntFlag: "Second stage min profit (%)",
          secondStageMaxLossPcntFlag: "Second stage max loss (%)",
          secondStageDayNumber: "Second stage day number",
          secondStageCompletePcnt: "Second stage complete (%)",
          firstDay: "First day",
          lastDay: "Last day",
          completed: "Completed",

          startBalance: "Start balance",
          leverage: "Leverage",
          active: "Active",
          deleted: "Deteled",
          tariff: "Tariff",
          user: "User",

          tariffPlanId: "Tariff plan ID",
          currentTariffStageId: "Current tariff level ID",
          currentTariffStageNumber: "Current tariff stage number",
          tariffStagesCount: "Tariff stages count",
          currentBillingStageId: "Current billing stage ID",
          currentDayId: "Current day Id",
          currentDayNumber: "Current day number",
          tradingDaysCount: "Trading days count",
          totalLast: "Final value",
          totalAccountBegin: "Beginning balance",
          totalAccountChange: "Account balance change",
          totalAccountPcnt: "Account percentage",
          selected: "Selected",
          status: "Status",
          statusAt: "Status at",
          statusMessage: "Status message",

          beginAt: "Start at",
          endAt: "End at",
          createdAt: "Created At",
          updatedAt: "Updated At",
        },
        filters: {},
        fieldGroups: {
          main: "Main",
          status: "Status",
        },
        page: {
          delete: "Delete Account",
        },
        errors: {},
      },
      stages: {
        name: "Stages",
        fields: {
          id: "Id",
          billingAccountId: "Billing account ID",
          tariffStageId: "Tariff stage ID",
          currentDayId: "Current day ID",
          totalLast: "Final value",
          totalStageBegin: "Beginning stage",
          totalStageChange: "Stage change",
          totalStagePcnt: "Stage percentage",
          minProfitPerStageProcentFlag: "Min stage profit (%)",
          maxLossPerStageProcentFlag: "Max stage loss (%)",
          stopAt: "Stop at",
          stopReason: "Stopping reason",
          complete: "Complete",
          createdAt: "Created At",
          updatedAt: "Updated At",
        },
        filters: {},
        fieldGroups: {
          main: "Main",
          status: "Status",
        },
        page: {
          delete: "Delete Account",
        },
        errors: {},
      },
      days: {
        name: "Days",
        fields: {
          id: "Id",
          billingStageId: "Billing stage Id",
          date: "Date",
          tradingDayNumber: "Day of trading",
          totalLast: "Final value",
          usdtValue24hOpen: "24h open price (USDT)",
          usdtValue24hChange: "24h change price (USDT)",
          usdtValue24hPcnt: "24h value price in (USDT)",
          createdAt: "Created at",
          updatedAt: "Updated at",
        },
        filters: {},
        fieldGroups: {
          main: "Main",
          status: "Status",
        },
        page: {
          delete: "Delete Account",
        },
        errors: {},
      },
      wallet: {
        name: "Wallets",
        fields: {
          id: "Id",
          account: "Account",
          coin: "Coin",
          balance: "Balance",
          equity: "Equity",
          reserved: "Reserved",
          usdtValue: "Value in USDT",
          usdValue: "Value in USD",
          usdtValue24hOpen: "24h open price (USDT)",
          usdtValue24hChange: "24h change price (USDT)",
          usdtValue24hPcnt: "24h value price in (USDT)",
          tariff: "Tariff",
          price: "Price",
          comment: "Comment",
          active: "Active",
          deleted: "Deteled",
          createdAt: "Created At",
          updatedAt: "Updated At",
        },
        filters: {},
        fieldGroups: {
          main: "Main",
          status: "Status",
        },
        page: {},
        errors: {},
      },
      payment: {
        name: "Payments",
        fields: {
          id: "Id",
          account: "Account",
          tariff: "Tariff",
          price: "Price",
          comment: "Comment",
          active: "Active",
          deleted: "Deteled",
          createdAt: "Created At",
          updatedAt: "Updated At",
        },
        filters: {},
        fieldGroups: {
          main: "Main",
          status: "Status",
        },
        page: {},
        errors: {},
      },
      contest: {
        name: "Contests",
        fields: {
          id: "Id",
          initialBalance: "InitialBalance",
          currentBalance: "CurrentBalance",
          finalBalance: "FinalBalance",
          minProfitPerStageProcent: "MinProfitPerStageProcent",
          maxLossPerStageProcent: "MaxLossPerStageProcent",
          condition: "Condition",
          currentProfit: "CurrentProfit",
          currentProfitProcent: "CurrentProfitProcent",
          endDayProfit: "EndDayProfit",
          endDayProfitProcent: "EndDayProfitProcent",
          endStageProfit: "EndStageProfit",
          endStageProfitPercent: "EndStageProfitPercent",
          tariffStageId: "TariffStageId",
          billingAccountId: "BillingAccountId",

          active: "Active",
          deleted: "Deteled",
          createdAt: "Created At",
          updatedAt: "Updated At",
        },
        filters: {},
        fieldGroups: {
          main: "Main",
          status: "Status",
        },
        page: {},
        errors: {},
      },
    },

    payments: {
      invoicesUsdt: {
        name: "Invoices USDT",
        fields: {
          id: "Id",
          billingAccountId: "Billing account id",
          invoiceCoin: "Invoice coin",
          invoiceCost: "Invoice cost",
          tempWalletAddress: "Temp wallet address",
          tempWalletHex: "Temp wallet hex",
          tempWalletPrivateKey: "Temp wallet private key",
          tempWalletPublicKey: "Temp wallet public key",
          status: "Status",
          statusAt: "Status At",
          ballanceUsdt: "Balance (USDT)",
          ballanceTrx: "Balance (TRX)",
          transactionHash: "Transaction hash",
          userWalletAddress: "User wallet address",
          mainWalletId: "Main wallet id",
          statusTransfer: "Status transfer",
          statusTransferAt: "Status transfer at",
          mainWallet: "Main wallet",
          createdAt: "Created At",
          updatedAt: "Updated At"
        },
        filters: {},
        fieldGroups: {
          main: "Main",
          status: "Status",
        },
        page: {
          delete: "Delete Invoice",
        },
        errors: {},
      },
      mainWallet: {
        name: "Main Wallet",
        fields: {
          id: "Id",
          mainWalletAddress: "Main Wallet Address",
          mainWalletHex: "Main Wallet Hex",
          mainWalletPrivateKey: "Main Wallet Private Key",
          mainWalletPublicKey: "Main Wallet Public Key",
          ballanceUsdt: "Balance (USDT)",
          ballanceTrx: "Balance (TRX)",
          trxAmount: "TRX Amount",
          feeLimit: "Fee Limit",
          default: "Default",
          deleted: "Deteled",
          createdAt: "Created At",
          updatedAt: "Updated At"
        },
        filters: {},
        fieldGroups: {
          main: "Main",
          status: "Status",
        },
        page: {
          delete: "Delete Invoice",
        },
        errors: {},
      },
    },

    faq: {
      categories: {
        name: "Categories",
        fields: {
          id: "Id",
          name: "Name",
          description: "Description",
          active: "Active",
          deleted: "Deteled",
          createdAt: "Created At",
          updatedAt: "Updated At",
        },
        filters: {},
        fieldGroups: {
          main: "Main",
          status: "Status",
        },
        page: {
          delete: "Delete Category",
        },
        errors: {},
      },
      entry: {
        name: "Entries",
        fields: {
          id: "Id",
          question: "Question",
          answer: "Answer",
          rating: "Rating",
          lang: "Language",
          faqCategoriesId: "Category ID",
          active: "Active",
          deleted: "Deteled",
          categoryName: "Category name",
          createdAt: "Created at",
          updatedAt: "Updated at",
        },
        filters: {},
        fieldGroups: {
          main: "Main",
          status: "Status",
        },
        page: {
          delete: "Delete Entry",
        },
        errors: {},
      },
    },

    helppage: {
      category: {
        name: "Categories",
        fields: {
          id: "Id",
          name: "Name",

          description: "Description",
          active: "Active",
          deleted: "Deteled",
          createdAt: "Created At",
          updatedAt: "Updated At",
        },
        filters: {},
        fieldGroups: {
          main: "Main",
          status: "Status",
        },
        page: {
          delete: "Delete Category",
        },
        errors: {},
      },
      entry: {
        name: "Entries",
        fields: {
          id: "Id",
          title: "Name",
          content: "Description",
          active: "Active",
          deleted: "Deteled",
          helppageCategoriesId: "Categories Id",
          createdAt: "Created At",
          updatedAt: "Updated At",
        },
        filters: {},
        fieldGroups: {
          main: "Main",
          status: "Status",
        },
        page: {
          delete: "Delete Entry",
        },
        errors: {},
      },
    },

    mainpage: {
      entry: {
        name: "Entries",
        fields: {
          id: "Id",
          title: "Name",
          content: "Description",
          active: "Active",
          deleted: "Deteled",
          createdAt: "Created At",
          updatedAt: "Updated At",
        },
        filters: {},
        fieldGroups: {
          main: "Main",
          status: "Status",
        },
        page: {
          delete: "Delete Entry",
        },
        errors: {},
      },
    },

    news: {
      category: {
        name: "Categories",
        fields: {
          id: "Id",
          name: "Name",
          newsCategoriesId: "Category ID",
          description: "Description",
          active: "Active",
          deleted: "Deteled",
          createdAt: "Created At",
          updatedAt: "Updated At",
        },
        filters: {},
        fieldGroups: {
          main: "Main",
          status: "Status",
        },
        page: {
          delete: "Delete Category",
        },
        errors: {},
      },
      posts: {
        name: "Posts",
        fields: {
          id: "Id",
          category: "Category",
          title: "Name",
          preview: "Preview",
          content: "Content",
          beginAt: "Begin ",
          endAt: "End",
          active: "Active",
          deleted: "Deteled",
          createdAt: "Created At",
          updatedAt: "Updated At",
        },
        filters: {},
        fieldGroups: {
          main: "Main",
          status: "Status",
        },
        page: {
          delete: "Delete Entry",
        },
        errors: {},
      },
    },

    trading: {
      executions: {
        name: "Executions",
        fields: {
          id: "Id",
          name: "Name",
          instrument: "Instrument",
          price: "Price",
          time: "Time",
          qty: "Qty",
          side: "Side",
          orderId: "orderId",
          isClose: "isClose",
          positionId: "positionId",
          commission: "commission",
          createdAt: "Created At",
          updatedAt: "Updated At",
        },
        filters: {},
        fieldGroups: {
          main: "Main",
          status: "Status",
        },
        page: {
          delete: "Delete Category",
        },
        errors: {},
      },
      orders: {
        name: "Orders",
        fields: {
          id: "Id",
          marketdataPairId: "PairId",
          instrument: "Instrument",
          transactionId: "Transaction Id",
          qty: "Qty",
          side: "Side",
          type: "Type",
          status: "Status",
          filledQty: "Filled Qty",
          avgPrice: "Price (avg)",
          limitPrice: "Limit price",
          stopPrice: "Stop price",
          trailingStopPips: "Trailing stop pips",
          isTrailingStop: "Stop trailing",
          isGuaranteedStop: "Stop guaranteed",
          parentId: "Parent Id",
          parentType: "Parent type",
          duration: "Duration",
          lastModified: "Last modified",
          commission: "Commission",
          messageType: "Message type",
          messageText: "Message text",
          createdAt: "Created At",
          updatedAt: "Updated At",
        },
        filters: {},
        fieldGroups: {
          main: "Main",
          status: "Status",
        },
        page: {
          delete: "Delete Category",
        },
        errors: {},
      },
      positions: {
        name: "Positions",
        fields: {
          id: "Id",
          unrealizedPl: "unrealizedPl",
          description: "Description",
          active: "Active",
          deleted: "Deteled",
          createdAt: "Created At",
          updatedAt: "Updated At",
        },
        filters: {},
        fieldGroups: {
          main: "Main",
          status: "Status",
        },
        page: {
          delete: "Delete Category",
        },
        errors: {},
      },
    },
  },
};

export default customEnglishMessages;
