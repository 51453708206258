import * as React from 'react';

import {
    AutocompleteInput,
    BooleanField,
    Datagrid,
    DateField,
    DateInput,
    DeleteButton,
    EditButton,
    FunctionField,
    List,
    NullableBooleanInput,
    NumberField,
    ReferenceField,
    ReferenceInput,
    SearchInput,
    SelectInput,
    TextField
} from 'react-admin';

import { useMediaQuery, Theme } from '@mui/material';



const filters = [
    <SearchInput source="q" alwaysOn />,
    <DateInput source="last_seen_gte" />,
    <NullableBooleanInput source="has_ordered" />,

];


const tariffPlanField = (record: any) => `#id${record.id} #${record.name}`;



const AccountList = () => {
    const isXsmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    return (
        <List
            filters={filters}
            sort={{ field: 'id', order: 'ASC' }}
            perPage={25}
        >

            <Datagrid
                optimized
                // rowClick="edit"
                sx={{
                    '& .column-groups': {
                        md: { display: 'none' },
                        lg: { display: 'table-cell' },
                    },
                }}
            >
                <TextField source="id" label="id" />
                <ReferenceField source="profileUserId" reference="profile/users/core" label="resources.billing.account.fields.user">
                    <TextField source="username" />
                </ReferenceField>

                <TextField source='invoiceCoin' label="resources.billing.account.fields.invoiceCoin" />
                <NumberField source='invoiceCost' label="resources.billing.account.fields.invoiceCost" />

                <ReferenceField source="tariffPlanId" reference="tariff-plans/core" label="resources.billing.account.fields.tariffPlanId">
                    <NumberField source="id" />
                </ReferenceField>

                <NumberField source='totalLast' label="resources.billing.account.fields.totalLast" />
                <NumberField source='totalAccountBegin' label="resources.billing.account.fields.totalAccountBegin" />
                <NumberField source='totalAccountChange' label="resources.billing.account.fields.totalAccountChange" />
                <NumberField source='totalAccountChangePcnt' label="resources.billing.account.fields.totalAccountChangePcnt" />
                <NumberField source='currentStageNumber' label="resources.billing.account.fields.currentStageNumber" />

                <NumberField source='firstStageBegin' label="resources.billing.account.fields.firstStageBegin" />
                <NumberField source='firstStageLast' label="resources.billing.account.fields.firstStageLast" />
                <NumberField source='firstStageChange' label="resources.billing.account.fields.firstStageChange" />
                <NumberField source='firstStageChangePcnt' label="resources.billing.account.fields.firstStageChangePcnt" />
                <BooleanField source='firstStageMinProfitPcntFlag' label="resources.billing.account.fields.firstStageMinProfitPcntFlag" />
                <BooleanField source='firstStageMaxLossPcntFlag' label="resources.billing.account.fields.firstStageMaxLossPcntFlag" />
                <NumberField source='firstStageDayNumber' label="resources.billing.account.fields.firstStageDayNumber" />
                <NumberField source='firstStageCompletePcnt' label="resources.billing.account.fields.firstStageCompletePcnt" />

                <NumberField source='secondStageBegin' label="resources.billing.account.fields.secondStageBegin" />
                <NumberField source='secondStageLast' label="resources.billing.account.fields.secondStageLast" />
                <NumberField source='secondStageChange' label="resources.billing.account.fields.secondStageChange" />
                <NumberField source='secondStageChangePcnt' label="resources.billing.account.fields.secondStageChangePcnt" />
                <BooleanField source='secondStageMinProfitPcntFlag' label="resources.billing.account.fields.secondStageMinProfitPcntFlag" />
                <BooleanField source='secondStageMaxLossPcntFlag' label="resources.billing.account.fields.secondStageMaxLossPcntFlag" />
                <NumberField source='secondStageDayNumber' label="resources.billing.account.fields.secondStageDayNumber" />
                <NumberField source='secondStageCompletePcnt' label="resources.billing.account.fields.secondStageCompletePcnt" />

                <NumberField source='currentDayId' label="resources.billing.account.fields.currentDayId" />
                <NumberField source='currentDayNumber' label="resources.billing.account.fields.currentDayNumber" />

                <NumberField source='tradingDaysCount' label="resources.billing.account.fields.tradingDaysCount" />

                <NumberField source='firstDay' label="resources.billing.account.fields.firstDay" />
                <NumberField source='lastDay' label="resources.billing.account.fields.lastDay" />

                <TextField source='status' label="resources.billing.account.fields.status" />
                <DateField source='statusAt' label="resources.billing.account.fields.statusAt" />
                <TextField source='statusMessage' label="resources.billing.account.fields.statusMessage" />

                <NumberField source='beginAt' label="resources.billing.account.fields.beginAt" />
                <NumberField source='endAt' label="resources.billing.account.fields.endAt" />

                <BooleanField source='completed' label="resources.billing.account.fields.completed" />

                <BooleanField source='selected' label="resources.billing.account.fields.selected" />






















                


                <NumberField source='currentTariffStageId' label="resources.billing.account.fields.currentTariffStageId" />
                <NumberField source='currentTariffStageNumber' label="resources.billing.account.fields.currentTariffStageNumber" />
                <NumberField source='tariffStagesCount' label="resources.billing.account.fields.tariffStagesCount" />
                <NumberField source='currentBillingStageId' label="resources.billing.account.fields.currentBillingStageId" />

                <DateField source="createdAt" showTime label="resources.billing.account.fields.createdAt" />
                <DateField source="updatedAt" showTime label="resources.billing.account.fields.updatedAt" />
                


                



                {/* <NumberField source='tariff_plans.realCostUsdt' label="resources.billing.account.fields.realCostUsdt" />
                <NumberField source='tariff_plans.startBalanceUsdt' label="resources.billing.account.fields.startBalance" />
                <NumberField source='tariff_plans.leverage' label="resources.billing.account.fields.leverage" />
                <NumberField source='tariff_plans.totalLast' label="resources.billing.account.fields.leverage" />
                <NumberField source='tariff_plans.totalAccountBegin' label="resources.billing.account.fields.leverage" />
                <NumberField source='tariff_plans.totalAccountChange' label="resources.billing.account.fields.leverage" />
                <NumberField source='tariff_plans.totalAccountPcnt' label="resources.billing.account.fields.leverage" /> */}

                {/* <BooleanField source="active" label="resources.billing.account.fields.active" /> */}
                

                <EditButton />
                <DeleteButton />

            </Datagrid>

        </List>
    );
};

export default AccountList;
