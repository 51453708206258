import CoinIcon from '@mui/icons-material/People';

import PairsList from './PairsList';
import PairsCreate from './PairsCreate';
import PairsEdit from './PairsEdit';

const resource = {
    list: PairsList,
    create: PairsCreate,
    edit: PairsEdit,
    icon: CoinIcon,
};

export default resource;
