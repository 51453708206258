import * as React from 'react';

import {
    AutocompleteInput,
    BooleanField,
    Datagrid,
    DateField,
    DateInput,
    DeleteButton,
    EditButton,
    FunctionField,
    List,
    NullableBooleanInput,
    NumberField,
    ReferenceField,
    ReferenceInput,
    SearchInput,
    TextField
} from 'react-admin';

import { useMediaQuery, Theme } from '@mui/material';



const filters = [
    <SearchInput source="q" alwaysOn />,
    <DateInput source="last_seen_gte" />,
    <NullableBooleanInput source="has_ordered" />,

];


const coinField = (record: any) => `#${record.id} #${record.name}`;


const QuotesList = () => {
    const isXsmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    return (
        <List
            filters={filters}
            sort={{ field: 'id', order: 'ASC' }}
            perPage={25}
        >

            <Datagrid
                optimized
                // rowClick="edit"
                sx={{
                    '& .column-groups': {
                        md: { display: 'none' },
                        lg: { display: 'table-cell' },
                    },
                }}
            >
                <NumberField source="id" label="id" />
                <NumberField source="datetimeUnixtimeMs" label="resources.marketdata.quotes.fields.datetimeUnixtimeMs" />
                <NumberField source="datetimeUnixtimeS" label="resources.marketdata.quotes.fields.datetimeUnixtimeS" />
                <DateField source="datetimeIso" label="resources.marketdata.quotes.fields.datetimeIso" />
                <NumberField source="bidPrice" label="resources.marketdata.quotes.fields.bidPrice" />
                <NumberField source="bidSize" label="resources.marketdata.quotes.fields.bidSize" />
                <NumberField source="askPrice" label="resources.marketdata.quotes.fields.askPrice" />
                <NumberField source="askSize" label="resources.marketdata.quotes.fields.askSize" />
                <NumberField source="lastPrice" label="resources.marketdata.quotes.fields.lastPrice" />
                <NumberField source="prevPrice24h" label="resources.marketdata.quotes.fields.prevPrice24h" />
                <NumberField source="price24hOpen" label="resources.marketdata.quotes.fields.price24hOpen" />
                <NumberField source="price24hChange" label="resources.marketdata.quotes.fields.price24hChange" />
                <NumberField source="price24hPcnt" label="resources.marketdata.quotes.fields.price24hPcnt" />
                <NumberField source="highPrice24h" label="resources.marketdata.quotes.fields.highPrice24h" />
                <NumberField source="lowPrice24h" label="resources.marketdata.quotes.fields.lowPrice24h" />
                <NumberField source="turnover24h" label="resources.marketdata.quotes.fields.turnover24h" />
                <NumberField source="volume24h" label="resources.marketdata.quotes.fields.volume24h" />
                <NumberField source="usdIndexPrice" label="resources.marketdata.quotes.fields.usdIndexPrice" />
                <TextField source="source" label="resources.marketdata.quotes.fields.source" />
                <NumberField source="marketdataPairId" label="resources.marketdata.quotes.fields.marketdataPairId" />

                <DateField source="createdAt" showTime label="resources.marketdata.quotes.fields.createdAt" />
                <DateField source="updatedAt" showTime label="resources.marketdata.quotes.fields.updatedAt" />

                {/* <DeleteButton /> */}

            </Datagrid>

        </List>
    );
};

export default QuotesList;
