import RateIcon from '@mui/icons-material/People';

import QuotesList from './QuotesList';
import QuotesCreate from './QuotesCreate';
import QuotesEdit from './QuotesEdit';

const resource = {
    list: QuotesList,
    create: QuotesCreate,
    edit: QuotesEdit,
    icon: RateIcon,
};

export default resource;
