import * as React from 'react';

import {
    DateInput,
    Edit,
    NullableBooleanInput,
    TextInput,
    PasswordInput,
    SimpleForm,
    useTranslate,
    BooleanInput,
    ReferenceInput,
    SelectInput,
    NumberInput,
} from 'react-admin';

import { Grid, Box, Typography } from '@mui/material';

import { validateForm } from './LastQuotesCreate';



// const title = () => <FullNameField size="32" sx={{ margin: '5px 0' }} />;

const coinField = (record: any) => `#${record.id} #${record.name}`;



const LastQuotesEdit = () => (

    <Edit>
        <SimpleForm
            sx={{ maxWidth: 500 }}
            validate={validateForm}
        >
            <SectionTitle label="resources.marketdata.rateLast.fieldGroups.main" />

            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <NumberInput source="costByUsd" isRequired fullWidth />
            </Box>

            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <ReferenceInput source="marketdataCoinId" reference="marketdata/coin">
                    <SelectInput optionText={coinField} isRequired />
                </ReferenceInput>
            </Box>

        </SimpleForm>

    </Edit>
);


const SectionTitle = ({ label }: { label: string }) => {
    const translate = useTranslate();

    return (
        <Typography variant="h6" gutterBottom>
            {translate(label as string)}
        </Typography>
    );
};

const Separator = () => <Box pt="1em" />;


export default LastQuotesEdit;
