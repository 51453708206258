import * as React from 'react';

import {
    AutocompleteInput,
    BooleanField,
    Datagrid,
    DateField,
    DateInput,
    DeleteButton,
    EditButton,
    FunctionField,
    List,
    NullableBooleanInput,
    NumberField,
    ReferenceField,
    ReferenceInput,
    SearchInput,
    SelectField,
    TextField
} from 'react-admin';

import { useMediaQuery, Theme } from '@mui/material';



const filters = [
    <SearchInput source="q" alwaysOn />,
    <DateInput source="last_seen_gte" />,
    <NullableBooleanInput source="has_ordered" />,

];


const stageField = (record: any) => `#${record.tariffPlanId} #${record.name}`;


const InvoicesUsdtList = () => {
    const isXsmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    return (
        <List
            filters={filters}
            sort={{ field: 'id', order: 'ASC' }}
            perPage={25}
        >

            <Datagrid
                optimized
                // rowClick="edit"
                sx={{
                    '& .column-groups': {
                        md: { display: 'none' },
                        lg: { display: 'table-cell' },
                    },
                }}
            >
                <NumberField source="id" label="resources.payments.invoicesUsdt.fields.id" />

                <NumberField source='billingAccountId' label="resources.payments.invoicesUsdt.fields.billingAccountId" />

                <TextField source='invoiceCoin' label="resources.payments.invoicesUsdt.fields.invoiceCoin" />

                <TextField source='invoiceCost' label="resources.payments.invoicesUsdt.fields.invoiceCost" />
                
                <TextField source='tempWalletAddress' label="resources.payments.invoicesUsdt.fields.tempWalletAddress" />

                <TextField source='tempWalletHex' label="resources.payments.invoicesUsdt.fields.tempWalletHex" />

                <TextField source='tempWalletPrivateKey' label="resources.payments.invoicesUsdt.fields.tempWalletPrivateKey" />

                <TextField source='tempWalletPublicKey' label="resources.payments.invoicesUsdt.fields.tempWalletPublicKey" />

                <TextField source='status' label="resources.payments.invoicesUsdt.fields.status" />

                <DateField source='statusAt' label="resources.payments.invoicesUsdt.fields.statusAt" />

                <NumberField source='ballanceUsdt' label="resources.payments.invoicesUsdt.fields.ballanceUsdt" />

                <NumberField source='ballanceTrx' label="resources.payments.invoicesUsdt.fields.ballanceTrx" />

                <NumberField source='transactionHash' label="resources.payments.invoicesUsdt.fields.transactionHash" />

                <NumberField source="userWalletAddress" label="resources.payments.invoicesUsdt.fields.userWalletAddress" />

                <NumberField source="mainWalletId" label="resources.payments.invoicesUsdt.fields.mainWalletId" />

                <TextField source='statusTransfer' label="resources.payments.invoicesUsdt.fields.statusTransfer" />

                <DateField source="statusTransferAt" label="resources.payments.invoicesUsdt.fields.statusTransferAt" />



                <DateField source="createdAt" showTime label="resources.billing.payment.fields.createdAt" />
                <DateField source="updatedAt" showTime label="resources.billing.payment.fields.updatedAt" />


                <EditButton />
                <DeleteButton />

            </Datagrid>

        </List>
    );
};

export default InvoicesUsdtList;
