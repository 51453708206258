import * as React from 'react';

import {
    AutocompleteInput,
    BooleanField,
    Datagrid,
    DateField,
    DateInput,
    DeleteButton,
    EditButton,
    FunctionField,
    List,
    NullableBooleanInput,
    NumberField,
    ReferenceField,
    ReferenceInput,
    SearchInput,
    TextField
} from 'react-admin';

import { useMediaQuery, Theme } from '@mui/material';



const filters = [
    <SearchInput source="q" alwaysOn />,
    <DateInput source="last_seen_gte" />,
    <NullableBooleanInput source="has_ordered" />,

];


const stageField = (record: any) => `#${record.tariffPlanId} #${record.name}`;


const OrdersList = () => {
    const isXsmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    return (
        <List
            filters={filters}
            sort={{ field: 'id', order: 'ASC' }}
            perPage={25}
        >

            <Datagrid
                optimized
                // rowClick="edit"
                sx={{
                    '& .column-groups': {
                        md: { display: 'none' },
                        lg: { display: 'table-cell' },
                    },
                }}
            >
                <NumberField source="id" label="id" />
                <NumberField source="transactionId" label="Transaction id" />
                <NumberField source="billingAccountId" label="resources.billing.wallet.fields.account" />
                <NumberField source="marketdataPairId" label="resources.trading.orders.fields.marketdataPairId" />

                <TextField source='instrument' label="resources.trading.orders.fields.instrument" />
                <TextField source='qty' label="resources.trading.orders.fields.qty" />
                <TextField source='side' label="resources.trading.orders.fields.side" />
                <TextField source='type' label="resources.trading.orders.fields.type" />
                <TextField source='status' label="resources.trading.orders.fields.status" />
                <TextField source='filledQty' label="resources.trading.orders.fields.filledQty" />
                <NumberField source='avgPrice' label="resources.trading.orders.fields.avgPrice" />
                <NumberField source='limitPrice' label="resources.trading.orders.fields.limitPrice" />
                <NumberField source='stopPrice' label="resources.trading.orders.fields.stopPrice" />
                <NumberField source='isGuaranteedStop' label="resources.trading.orders.fields.isGuaranteedStop" />
                <NumberField source='parentId' label="resources.trading.orders.fields.parentId" />
                <TextField source='parentType' label="resources.trading.orders.fields.parentType" />
                <TextField source='duration' label="resources.trading.orders.fields.duration" />
                <TextField source='lastModified' label="resources.trading.orders.fields.lastModified" />
                <TextField source='commission' label="resources.trading.orders.fields.commission" />
                <TextField source='messageType' label="resources.trading.orders.fields.messageType" />
                <TextField source='messageText' label="resources.trading.orders.fields.messageText" />

                <DateField source="createdAt" showTime label="resources.trading.orders.fields.createdAt" />
                <DateField source="updatedAt" showTime label="resources.trading.orders.fields.updatedAt" />

                <EditButton />
                <DeleteButton />

            </Datagrid>

        </List>
    );
};

export default OrdersList;
