import * as React from 'react';
import {
    Create,
    DateInput,
    SimpleForm,
    TextInput,
    useTranslate,
    PasswordInput,
    email,
    BooleanInput,
    ReferenceInput,
    SelectInput,
    NumberInput,
    TextField,
    BooleanField,
    DateField,
    NumberField,
} from 'react-admin';
import { Box, Typography } from '@mui/material';


export const validateForm = (
    values: Record<string, any>
): Record<string, any> => {
    const errors = {} as any;
    // if (!values.billingAccountId) {
    //     errors.billingAccountId = 'ra.validation.required';
    // }
    return errors;
};


const accounsField = (record: any) => `#${record.tariffPlanId} #${record.name}`;


const InvoicesUsdtCreate = () => (
    <Create>
        <SimpleForm
            sx={{ maxWidth: 500 }}
            defaultValues={{
                isPositive: true,
                active: true,
            }}
            validate={validateForm}
        >
            <Separator />
            <SectionTitle label="resources.payments.invoicesUsdt.fieldGroups.main" />


                <NumberField source="id" label="resources.payments.invoicesUsdt.fields.id" />

                <NumberField source='billingAccountId' label="resources.payments.invoicesUsdt.fields.billingAccountId" />

                <TextField source='invoiceCoin' label="resources.payments.invoicesUsdt.fields.invoiceCoin" />

                <TextField source='invoiceCost' label="resources.payments.invoicesUsdt.fields.invoiceCost" />
                
                <TextField source='tempWalletAddress' label="resources.payments.invoicesUsdt.fields.tempWalletAddress" />

                <TextField source='tempWalletHex' label="resources.payments.invoicesUsdt.fields.tempWalletHex" />

                <TextField source='tempWalletPrivateKey' label="resources.payments.invoicesUsdt.fields.tempWalletPrivateKey" />

                <TextField source='tempWalletPublicKey' label="resources.payments.invoicesUsdt.fields.tempWalletPublicKey" />

                <TextField source='status' label="resources.payments.invoicesUsdt.fields.status" />

                <DateField source='statusAt' label="resources.payments.invoicesUsdt.fields.statusAt" />

                <NumberField source='ballanceUsdt' label="resources.payments.invoicesUsdt.fields.ballanceUsdt" />

                <NumberField source='ballanceTrx' label="resources.payments.invoicesUsdt.fields.ballanceTrx" />

                <NumberField source='transactionHash' label="resources.payments.invoicesUsdt.fields.transactionHash" />

                <NumberField source="userWalletAddress" label="resources.payments.invoicesUsdt.fields.userWalletAddress" />

                <NumberField source="mainWalletId" label="resources.payments.invoicesUsdt.fields.mainWalletId" />

                <TextField source='statusTransfer' label="resources.payments.invoicesUsdt.fields.statusTransfer" />

                <DateField source="statusTransferAt" label="resources.payments.invoicesUsdt.fields.statusTransferAt" />



                <DateField source="createdAt" showTime label="resources.billing.payment.fields.createdAt" />
                <DateField source="updatedAt" showTime label="resources.billing.payment.fields.updatedAt" />

                <NumberField source='mainWallet' label="resources.payments.invoicesUsdt.fields.mainWallet" />

            {/* <ReferenceInput source="accountId" reference="billing/accounts">
                <SelectInput optionText="id" isRequired fullWidth />
            </ReferenceInput>

            <ReferenceInput source="tariffPlanId" reference="tariff/plans">
                <SelectInput optionText="name" isRequired fullWidth />
            </ReferenceInput>

            <NumberInput source='price' label="resources.billing.payment.fields.price" />
            <TextInput source='comment' label="resources.billing.payment.fields.comment" /> */}

            <Separator />
            {/* <SectionTitle label="resources.billing.payment.fieldGroups.status" /> */}

            {/* <Box display={{ xs: 'block', sm: 'flex' }}>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                    <BooleanInput source="active" fullWidth />
                </Box>
            </Box> */}

        </SimpleForm>
    </Create>
);


const SectionTitle = ({ label }: { label: string }) => {
    const translate = useTranslate();

    return (
        <Typography variant="h6" gutterBottom>
            {translate(label as string)}
        </Typography>
    );
};

const Separator = () => <Box pt="1em" />;


export default InvoicesUsdtCreate;
