import * as React from 'react';

import {
    AutocompleteInput,
    BooleanField,
    Datagrid,
    DateField,
    DateInput,
    DeleteButton,
    EditButton,
    FunctionField,
    List,
    NullableBooleanInput,
    NumberField,
    ReferenceField,
    ReferenceInput,
    SearchInput,
    TextField
} from 'react-admin';

import { useMediaQuery, Theme } from '@mui/material';



const filters = [
    <SearchInput source="q" alwaysOn />,
    <DateInput source="last_seen_gte" />,
    <NullableBooleanInput source="has_ordered" />,

];


const stageField = (record: any) => `#${record.tariffPlanId} #${record.name}`;


const WalletList = () => {
    const isXsmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    return (
        <List
            filters={filters}
            sort={{ field: 'id', order: 'ASC' }}
            perPage={25}
        >

            <Datagrid
                optimized
                // rowClick="edit"
                sx={{
                    '& .column-groups': {
                        md: { display: 'none' },
                        lg: { display: 'table-cell' },
                    },
                }}
            >
                <NumberField source="id" label="id" />
                <NumberField source='billingAccountId' label="resources.billing.wallet.fields.account" />
                <NumberField source='marketdataCoinId' label="resources.billing.wallet.fields.coin" />
                <NumberField source='balance' label="resources.billing.wallet.fields.balance" />
                <NumberField source='equity' label="resources.billing.wallet.fields.equity" />
                <NumberField source='reserved' label="resources.billing.wallet.fields.reserved" />
                <NumberField source='usdtValue' label="resources.billing.wallet.fields.usdtValue" />
                <NumberField source='usdValue' label="resources.billing.wallet.fields.usdValue" />
                <NumberField source='usdtValue24hOpen' label="resources.billing.wallet.fields.usdtValue24hOpen" />
                <NumberField source='usdtValue24hChange' label="resources.billing.wallet.fields.usdtValue24hChange" />
                <NumberField source='usdtValue24hPcnt' label="resources.billing.wallet.fields.usdtValue24hPcnt" />

                {/* <ReferenceField source="accountId" reference="billing/accounts" label="resources.billing.wallet.fields.account">
                    <TextField source="id" />
                </ReferenceField>

                <ReferenceField source="tariffPlanId" reference="tariff/plans" label="resources.billing.wallet.fields.tariff">
                    <TextField source="name" />
                </ReferenceField> */}

                {/* <NumberField source='price' label="resources.billing.wallet.fields.price" />
                <TextField source='comment' label="resources.billing.wallet.fields.comment" /> */}

                {/* <BooleanField source="active" label="resources.billing.wallet.fields.active" /> */}
                <DateField source="createdAt" showTime label="resources.billing.wallet.fields.createdAt" />
                <DateField source="updatedAt" showTime label="resources.billing.wallet.fields.updatedAt" />

                <EditButton />
                <DeleteButton />

            </Datagrid>

        </List>
    );
};

export default WalletList;
