import * as React from 'react';

import {
    AutocompleteInput,
    BooleanField,
    Datagrid,
    DateField,
    DateInput,
    DeleteButton,
    EditButton,
    FunctionField,
    List,
    NullableBooleanInput,
    NumberField,
    ReferenceField,
    ReferenceInput,
    SearchInput,
    SelectField,
    TextField
} from 'react-admin';

import { useMediaQuery, Theme } from '@mui/material';



const filters = [
    <SearchInput source="q" alwaysOn />,
    <DateInput source="last_seen_gte" />,
    <NullableBooleanInput source="has_ordered" />,

];


const stageField = (record: any) => `#${record.tariffPlanId} #${record.name}`;


const InvoicesUsdtList = () => {
    const isXsmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    return (
        <List
            filters={filters}
            sort={{ field: 'id', order: 'ASC' }}
            perPage={25}
        >

            <Datagrid
                optimized
                // rowClick="edit"
                sx={{
                    '& .column-groups': {
                        md: { display: 'none' },
                        lg: { display: 'table-cell' },
                    },
                }}
            >
                <NumberField source="id" label="resources.payments.mainWallet.fields.id" />

                <TextField source='mainWalletAddress' label="resources.payments.mainWallet.fields.mainWalletAddress" />

                <TextField source='mainWalletHex' label="resources.payments.mainWallet.fields.mainWalletHex" />

                <TextField source='mainWalletPrivateKey' label="resources.payments.mainWallet.fields.mainWalletPrivateKey" />
                
                <TextField source='mainWalletPublicKey' label="resources.payments.mainWallet.fields.mainWalletPublicKey" />

                <TextField source='ballanceUsdt' label="resources.payments.mainWallet.fields.ballanceUsdt" />

                <TextField source='ballanceTrx' label="resources.payments.mainWallet.fields.ballanceTrx" />

                <TextField source='trxAmount' label="resources.payments.mainWallet.fields.trxAmount" />

                <TextField source='feeLimit' label="resources.payments.mainWallet.fields.feeLimit" />

                <BooleanField source='default' label="resources.payments.mainWallet.fields.default" />

                <BooleanField source='deleted' label="resources.payments.mainWallet.fields.deleted" />




                <DateField source="createdAt" showTime label="resources.payments.mainWallet.fields.createdAt" />
                <DateField source="updatedAt" showTime label="resources.payments.mainWallet.fields.updatedAt" />


                <EditButton />
                <DeleteButton />

            </Datagrid>

        </List>
    );
};

export default InvoicesUsdtList;
