import * as React from 'react';

import {
    AutocompleteInput,
    BooleanField,
    Datagrid,
    DateField,
    DateInput,
    DeleteButton,
    EditButton,
    FunctionField,
    List,
    NullableBooleanInput,
    NumberField,
    ReferenceField,
    ReferenceInput,
    SearchInput,
    TextField
} from 'react-admin';

import { useMediaQuery, Theme } from '@mui/material';



const filters = [
    <SearchInput source="q" alwaysOn />,
    <DateInput source="last_seen_gte" />,
    <NullableBooleanInput source="has_ordered" />,

];


// const stageField = (record: any ) => `#${record.tariffPlanId} #${record.name}`;


const PairsList = () => {
    const isXsmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    return (
        <List
            filters={filters}
            sort={{ field: 'id', order: 'ASC' }}
            perPage={25}
        >
            <Datagrid
                optimized
                // rowClick="edit"
                sx={{
                    '& .column-groups': {
                        md: { display: 'none' },
                        lg: { display: 'table-cell' },
                    },
                }}
            >
                <NumberField source="id" label="id" />
                <TextField source='type' label="resources.marketdata.pairs.fields.type" />
                <NumberField source='baseAssetId' label="resources.marketdata.pairs.fields.baseAssetId" />
                <NumberField source='quoteAssetId' label="resources.marketdata.pairs.fields.quoteAssetId" />
                <TextField source='symbol' label="resources.marketdata.pairs.fields.symbol" />
                <TextField source='name' label="resources.marketdata.pairs.fields.name" />
                <TextField source='description' label="resources.marketdata.pairs.fields.description" />
                <TextField source='image' label="resources.marketdata.pairs.fields.image" />
                <TextField source='timezone' label="resources.marketdata.pairs.fields.timezone" />
                <NumberField source='minmov' label="resources.marketdata.pairs.fields.minmov" />
                <NumberField source='minmov2' label="resources.marketdata.pairs.fields.minmov2" />
                <NumberField source='pointvalue' label="resources.marketdata.pairs.fields.pointvalue" />
                <NumberField source='pricescale' label="resources.marketdata.pairs.fields.pricescale" />
                <DateField source="session" showTime label="resources.marketdata.pairs.fields.session" />
                <BooleanField source="has_intraday" label="resources.marketdata.pairs.fields.hasIntraday" />
                <TextField source='visible_plots_set' label="resources.marketdata.pairs.fields.visiblePlotsSet" />
                
                <BooleanField source="active" label="resources.marketdata.pairs.fields.active" />
                <BooleanField source="deleted" label="resources.marketdata.pairs.fields.deleted" />
                
                <DateField source="createdAt" showTime label="resources.marketdata.pairs.fields.createdAt" />
                <DateField source="updatedAt" showTime label="resources.marketdata.pairs.fields.updatedAt" />

                <EditButton />
                <DeleteButton />
            </Datagrid>
        </List>
    );
};

export default PairsList;
