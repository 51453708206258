import * as React from 'react';

import {
    BooleanField,
    Datagrid,
    DateField,
    DateInput,
    List,
    NullableBooleanInput,
    NumberField,
    SearchInput,
    Link,
    useRecordContext, FieldProps,
    TextField,
    DeleteButton,
    EditButton,
    ReferenceField
} from 'react-admin';

import { useMediaQuery, Theme, SxProps, Typography } from '@mui/material';


import MobileGrid from './MobileGrid';
import UserListAside from './UserListAside';

import AvatarField from './AvatarField';
import { IProfileUser } from './types';

interface Props extends FieldProps<IProfileUser> {
    size?: string;
    sx?: SxProps;
}



const userFilters = [
    <SearchInput source="q" alwaysOn />,
    <DateInput source="lastSeen_gte" />,
    <NullableBooleanInput source="has_ordered" />,
    <NullableBooleanInput source="has_newsletter" defaultValue />,
];

const UserList = (props: Props) => {
    const isXsmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    const { size } = props;
    const record = useRecordContext<IProfileUser>();


    return (
        <List
            filters={isSmall ? userFilters : undefined}
            sort={{ field: 'lastSeen', order: 'DESC' }}
            perPage={25}
        // aside={<UserListAside />}
        >
            {isXsmall ? (
                <MobileGrid />
            ) : (
                <Datagrid
                    optimized
                    rowClick="edit"
                    sx={{
                        '& .column-groups': {
                            md: { display: 'none' },
                            lg: { display: 'table-cell' },
                        },
                    }}
                >
                    <NumberField source='id' label="resources.profile.users.fields.id" />
                    <NumberField source='profileRoleId' label="resources.profile.users.fields.profileRoleId" />

                    <Link to="" label="resources.profile.users.fields.username">
                        <TextField source='username' />
                    </Link>
                    <TextField source='email' label="resources.profile.users.fields.email" />
                    <TextField source='phone' label="resources.profile.users.fields.phone" />

                    <AvatarField record={record} size={size}
                        sx={{
                            mr: 1,
                            mt: -0.5,
                            mb: -0.5,
                        }}

                        label="resources.profile.users.fields.avatar"
                    />
                    <TextField 
                        source='firstName' 
                        label="resources.profile.users.fields.firstName"
                        sx={{ 
                            '& span': { 
                                minWidth: '20000px',
                                display: 'inline-block'
                            }
                        }}
                    />
                    <TextField source='lastName' label="resources.profile.users.fields.lastName" />
                    <TextField source="firstSeen" label="resources.profile.users.fields.firstSeen" />
                    <TextField source="lastSeen" label="resources.profile.users.fields.lastSeen" />

                    <NumberField source='totalBeginOpen' label="resources.profile.users.fields.totalBeginOpen" />
                    <NumberField source='totalLast' label="resources.profile.users.fields.totalLast" />
                    <NumberField source='totalBeginChange' label="resources.profile.users.fields.totalBeginChange" />
                    <NumberField source='totalBeginPcnt' label="resources.profile.users.fields.totalBeginPcnt" />

                    <BooleanField source='active' label="resources.profile.users.fields.active" />
                    <BooleanField source='deleted' label="resources.profile.users.fields.deleted" />
                    <DateField source='createdAt' label="resources.profile.users.fields.createdAt" />
                    <DateField source='updatedAt' label="resources.profile.users.fields.updatedAt" />

                    <EditButton />
                    <DeleteButton />

                </Datagrid>
            )}
        </List>
    );
};

export default UserList;
