import * as React from 'react';

import {
    DateInput,
    Edit,
    NullableBooleanInput,
    TextInput,
    PasswordInput,
    SimpleForm,
    useTranslate,
    BooleanInput,
    ReferenceInput,
    SelectInput,
    NumberInput,
} from 'react-admin';

import { Grid, Box, Typography } from '@mui/material';

import Aside from './Aside';
import { validateForm } from './UserCreate';




// const UserTitle = () => <FullNameField size="32" sx={{ margin: '5px 0' }} />;


const UserEdit = () => (

    <Edit
    // title={<UserTitle />} 
    // aside={<Aside />}
    >
        <SimpleForm
            sx={{ maxWidth: 500 }}
            validate={validateForm}

        >
            <SectionTitle label="resources.profile.users.fieldGroups.identity" />

            <ReferenceInput source="profileRoleId" reference="profile/roles/core" label="resources.profile.users.fields.profileRoleId">
                <SelectInput optionText="id" isRequired />
            </ReferenceInput>

            <TextInput source="username" isRequired fullWidth label="resources.profile.users.fields.username"/>

            <Box display={{ xs: 'block', sm: 'flex' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    <PasswordInput source="password" fullWidth label="resources.profile.users.fields.password"/>
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                    <PasswordInput source="confirm_password" fullWidth label="resources.profile.users.fields.confirm_password"/>
                </Box>
            </Box>

            <TextInput type="email" source="email" isRequired fullWidth label="resources.profile.users.fields.email"/>
            <TextInput type="phone" source="phone" isRequired fullWidth label="resources.profile.users.fields.phone"/>

            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    <TextInput source="first_name" isRequired fullWidth label="resources.profile.users.fields.firstName"/>
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                    <TextInput source="last_name" isRequired fullWidth label="resources.profile.users.fields.lastName"/>
                </Box>
            </Box>

            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <BooleanInput source="active" fullWidth label="resources.profile.users.fields.active"/>
            </Box>


        </SimpleForm>
    </Edit>

);



const SectionTitle = ({ label }: { label: string }) => {
    const translate = useTranslate();

    return (
        <Typography variant="h6" gutterBottom>
            {translate(label as string)}
        </Typography>
    );
};

const Separator = () => <Box pt="1em" />;


export default UserEdit;
