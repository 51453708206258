import * as React from 'react';
import {
    Create,
    DateInput,
    SimpleForm,
    TextInput,
    useTranslate,
    PasswordInput,
    email,
    BooleanInput,
    ReferenceInput,
    SelectInput,
    NumberInput,
} from 'react-admin';
import { Box, Typography } from '@mui/material';


export const validateForm = (
    values: Record<string, any>
): Record<string, any> => {
    const errors = {} as any;
    // if (!values.name) {
    //     errors.name = 'ra.validation.required';
    // }
    return errors;
};


const tariffPlanField = (record: any) => `#id${record.id} #${record.tariff_category.name} #${record.name}`;



const AccountCreate = () => (
    <Create>
        <SimpleForm
            sx={{ maxWidth: 500 }}
            defaultValues={{
                active: true,
            }}
            validate={validateForm}
        >
            <Separator />
            <SectionTitle label="resources.billing.account.fieldGroups.main" />

            <ReferenceInput source="profileUserId" reference="profile/users/core" label="resources.billing.account.fields.profileUserId">
                <SelectInput optionText="id" isRequired fullWidth />
            </ReferenceInput>

            <TextInput source='invoiceCoin' label="resources.billing.account.fields.invoiceCoin" />
                <NumberInput source='invoiceCost' label="resources.billing.account.fields.invoiceCost" />

                <ReferenceInput source="tariffPlanId" reference="tariff-plans/core" label="resources.billing.account.fields.tariffPlanId">
                    <SelectInput optionText="id" isRequired fullWidth />
                </ReferenceInput>

                <TextInput source='status' label="resources.billing.account.fields.status" />
                <TextInput source='statusMessage' label="resources.billing.account.fields.statusMessage" />

                <NumberInput source='beginAt' label="resources.billing.account.fields.beginAt" />

                <NumberInput source='endAt' label="resources.billing.account.fields.endAt" />

                <BooleanInput source='completed' label="resources.billing.account.fields.completed" />

                <BooleanInput source='selected' label="resources.billing.account.fields.selected" />









                

                

                
                

               

        </SimpleForm>
    </Create>
);


const SectionTitle = ({ label }: { label: string }) => {
    const translate = useTranslate();

    return (
        <Typography variant="h6" gutterBottom>
            {translate(label as string)}
        </Typography>
    );
};

const Separator = () => <Box pt="1em" />;


export default AccountCreate;
