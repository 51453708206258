import * as React from 'react';

import {
    AutocompleteInput,
    BooleanField,
    Datagrid,
    DateField,
    DateInput,
    DeleteButton,
    EditButton,
    FunctionField,
    List,
    NullableBooleanInput,
    NumberField,
    ReferenceField,
    ReferenceInput,
    SearchInput,
    TextField
} from 'react-admin';

import { useMediaQuery, Theme } from '@mui/material';



const filters = [
    <SearchInput source="q" alwaysOn />,
    <DateInput source="last_seen_gte" />,
    <NullableBooleanInput source="has_ordered" />,

];


// const stageField = (record: any ) => `#${record.tariffPlanId} #${record.name}`;


const CoinList = () => {
    const isXsmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    return (
        <List
            filters={filters}
            sort={{ field: 'id', order: 'ASC' }}
            perPage={25}
        >
            <Datagrid
                optimized
                // rowClick="edit"
                sx={{
                    '& .column-groups': {
                        md: { display: 'none' },
                        lg: { display: 'table-cell' },
                    },
                }}
            >
                <NumberField source="id" label="id" />

                <TextField source='symbol' label="resources.marketdata.coin.fields.symbol" />
                <TextField source='name' label="resources.marketdata.coin.fields.name" />
                {/* <TextField source='image' label="resources.marketdata.coin.fields.name" /> */}
                <NumberField source='datetimeUnixtimeMs' label="resources.marketdata.coin.fields.datetimeUnixtimeMs" />
                <NumberField source='datetimeUnixtimeS' label="resources.marketdata.coin.fields.datetimeUnixtimeS" />
                <NumberField source='datetimeIso' label="resources.marketdata.coin.fields.datetimeIso" />
                <NumberField source='bidPrice' label="resources.marketdata.coin.fields.bidPrice" />
                <NumberField source='bidSize' label="resources.marketdata.coin.fields.bidSize" />
                <NumberField source='askPrice' label="resources.marketdata.coin.fields.askPrice" />
                <NumberField source='askSize' label="resources.marketdata.coin.fields.askSize" />
                <NumberField source='lastPrice' label="resources.marketdata.coin.fields.lastPrice" />
                <NumberField source='prevPrice24h' label="resources.marketdata.coin.fields.prevPrice24h" />
                <NumberField source='price24hOpen' label="resources.marketdata.coin.fields.price24hOpen" />
                <NumberField source='price24hChange' label="resources.marketdata.coin.fields.price24hChange" />
                <NumberField source='price24hPcnt' label="resources.marketdata.coin.fields.price24hPcnt" />
                <NumberField source='highPrice24h' label="resources.marketdata.coin.fields.highPrice24h" />
                <NumberField source='lowPrice24h' label="resources.marketdata.coin.fields.lowPrice24h" />
                <NumberField source='turnover24h' label="resources.marketdata.coin.fields.turnover24h" />
                <NumberField source='volume24h' label="resources.marketdata.coin.fields.volume24h" />
                <NumberField source='usdIndexPrice' label="resources.marketdata.coin.fields.usdIndexPrice" />
                <NumberField source='source' label="resources.marketdata.coin.fields.source" />

                <BooleanField source="active" label="resources.marketdata.coin.fields.active" />
                <BooleanField source="deleted" label="resources.marketdata.coin.fields.deleted" />

                <DateField source="createdAt" showTime label="resources.marketdata.coin.fields.createdAt" />
                <DateField source="updatedAt" showTime label="resources.marketdata.coin.fields.updatedAt" />

                <EditButton />
                <DeleteButton />
            </Datagrid>
        </List>
    );
};

export default CoinList;
