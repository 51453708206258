import AccountIcon from '@mui/icons-material/People';

import DaysList from './DaysList';
// import AccountCreate from './AccountCreate';
// import AccountEdit from './AccountEdit';

const resource = {
    list: DaysList,
    // create: AccountCreate,
    // edit: AccountEdit,
    icon: AccountIcon,
};

export default resource;
